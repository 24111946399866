<template>
	<v-container fluid class="width-80">
		<v-card flat class="custom-grey-border remove-border-radius">
			<v-card-title class="headline grey lighten-4 py-2">
				<v-row>
					<v-col md="4" class="py-0 my-auto">
						<h3 class="font-weight-700 custom-headline color-custom-blue" style="margin: 3.5px 0px">
							Roles
						</h3>
					</v-col>
					<template>
						<v-col md="8" class="text-right py-0 my-auto">
							<v-btn
								tile
								depressed
								color="white--text"
								class="blue darken-4"
								v-on:click="$router.push({ name: 'role.create', query: { ...$route.query } })"
							>
								<span class="font-size-16 font-weight-600">Create</span>
							</v-btn>
						</v-col>
					</template>
				</v-row>
			</v-card-title>
			<v-card-text class="px-0 py-3 font-size-16">
				<perfect-scrollbar
					:options="{ suppressScrollX: true }"
					class="scroll custom-box-top-inner-shadow"
				>
					<div class="v-data-table bt-table permission-height theme--light">
						<div class="v-data-table__wrapper">
							<table width="100%">
								<thead>
									<tr>
										<th width="50">#</th>
										<th width="200">Role name</th>
										<th width="200">Users</th>
										<th></th>
									</tr>
								</thead>
								<tbody v-if="roles.length > 0">
									<tr v-for="(row, index) in roles" :key="index">
										<td width="50" class="">{{ index + 1 }}.</td>
										<td width="200" class="simple-table-td text-uppercase">
											{{ row.role }}
										</td>
										<td width="200" class="simple-table-td">
											{{ row.users_count }}
										</td>
										<td class="simple-table-td">
											<template>
												<v-btn-toggle dense v-model="role_action[index]">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																v-on="on"
																v-bind="attrs"
																small
																v-if="!row.super_admin"
																v-on:click="
																	$router.push({
																		name: 'role.update',
																		params: { id: row.id },
																		query: { ...$route.query },
																	})
																"
															>
																<v-icon small>mdi-pencil</v-icon>
															</v-btn>
														</template>
														<span>Edit Role</span>
													</v-tooltip>
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																v-on="on"
																v-bind="attrs"
																small
																@click="
																	$router.push({ path: 'role/create', query: { clone: row.id, ...$route.query } })
																"
															>
																<v-icon small>mdi-content-copy</v-icon>
															</v-btn>
														</template>
														<span>Clone Role</span>
													</v-tooltip>
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																:disabled="row.users_count > 0"
																v-on="on"
																v-bind="attrs"
																small
																v-if="row.can_delete"
																v-on:click="deleteConfirmation(row)"
															>
																<v-icon small>mdi-delete</v-icon>
															</v-btn>
														</template>
														<span>Delete Role</span>
													</v-tooltip>
												</v-btn-toggle>
											</template>
										</td>
									</tr>
								</tbody>
								<tfoot v-else>
									<tr v-if="roleLoading">
										<td class="simple-table-td" v-for="(di, did) in 4" :key="did">
											<v-skeleton-loader type="text" class="custom-skeleton"></v-skeleton-loader>
										</td>
									</tr>
									<tr v-else>
										<td colspan="4" class="simple-table-td">
											<p class="m-0 row-not-found text-center font-weight-500 font-size-16">
												<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
												Uhh... There are no role at the moment.
											</p>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</perfect-scrollbar>
			</v-card-text>
		</v-card>
		<!-- <RoleDelete
      :deleteDialog="roleDeleteDialog"
      :requestUrl="roleDeleteRequestURL"
      v-on:delete:success="getRoles"
      v-on:delete:close="roleDeleteDialog = false"
    ></RoleDelete> -->
		<DeleteTemplate
			type="role"
			:delete-text="deleteText"
			v-on:success="
				getRoles();
				deleteDialog = false;
			"
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			:delete-url="deleteURL"
			:delete-note="null"
		>
		</DeleteTemplate>
	</v-container>
</template>

<script>
import { GetRoles } from "@/core/lib/setting.lib";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { mapGetters } from "vuex";
import HeightMixin from "@/core/mixins/height.mixin";
export default {
	data() {
		return {
			skipBottomHeight: 23,
			role_action: [],
			roleLoading: false,
			deleteDialog: false,
			deleteText: null,
			deleteURL: null,
			roleDeleteRequestURL: "",
			roles: [],
		};
	},
	mixins: [HeightMixin],
	components: {
		DeleteTemplate,
	},
	methods: {
		deleteConfirmation(data) {
			this.deleteText = `${data.role}`;
			this.deleteURL = `role/${data.id}`;
			this.deleteDialog = true;
		},
		getRoles() {
			const _this = this;
			_this.roleLoading = true;
			GetRoles()
				.then((data) => {
					_this.roles = data;
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.roleLoading = false;
					_this.roleDeleteDialog = false;
					_this.roleDeleteRequestURL = "";
				});
		},
	},
	mounted() {
		this.getRoles();
	},
	computed: {
		...mapGetters(["currentUser"]),
	},
};
</script>
